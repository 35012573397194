import React, { FC, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useMarkdown from "../hooks/useMarkdown";
import styled from "styled-components";
import { Dropup, Dropdown } from "../components/Icons";
import { AnimatePresence, motion } from "framer-motion";

const Content = styled.div`
  padding: var(--s12);
  h2 {
    margin-bottom: var(--s4);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  @media screen and (max-width: 700px) {
    padding: var(--s4);
  }
  h3,
  h4 {
    margin-top: var(--s12);
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  a,
  a:visited,
  a:link,
  a:active {
    display: block;
    color: black;
    text-decoration: underline;
  }
  p {
    font-weight: 300;
    max-width: 90ch;
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  p,
  a {
    font-size: 2rem;
    line-height: 3.3rem;
    @media screen and (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  p a {
    margin-top: -0.25rem;
  }

  div hr + p a {
    font-weight: semibold;
  }

  p,
  a {
    font-weight: 400;
  }

  div p a {
    display: inline-block !important;
  }

  div hr + p + p a:first-of-type {
    margin-top: var(--s2);
  }

  div hr + p + p a {
    font-size: 1.5rem;
    font-weight: 400;
  }

  hr:not(:first-of-type) {
    margin-top: var(--s16);
  }
`;

const KunstsammlungenPage: FC = () => {
  const kunstsammlungen = useMarkdown("kunstsammlungen")[0];

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && kunstsammlungen.html) {
      ref.current.querySelectorAll("a").forEach(node => {
        if (node.getAttribute("href")?.includes("heller")) {
          node.setAttribute("target", "_self");
        }
      });
    }
  }, [kunstsammlungen]);

  return (
    <Layout activePage={Routes.KUNSTSAMMLUNGEN}>
      <SEO title="Kunstsammlungen" />
      <Content>
        <div ref={ref} dangerouslySetInnerHTML={{ __html: kunstsammlungen.html }} />
      </Content>
    </Layout>
  );
};

export default KunstsammlungenPage;
